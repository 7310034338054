import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faTwitter, faGithub, faMedium } from "@fortawesome/free-brands-svg-icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export type IconListProps = {
  email: string
  site?: string | null
  links: Record<string, string>
}

const IconList: React.FC<IconListProps> = ({ email, site, links }) => (
  <div className="icon-list">
    <a href={`mailto:${email}`}>
      <FontAwesomeIcon icon={faEnvelope} />
    </a>
    {site && (
      <OutboundLink
        href={`${site}`}
        title="Personal site"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLink} />
      </OutboundLink>
    )}
    {links.instagram && (
      <OutboundLink
        href={`${links.instagram}`}
        title="Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </OutboundLink>
    )}
    {links.github && (
      <OutboundLink
        href={`${links.github}`}
        title="GitHub"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} />
      </OutboundLink>
    )}
    {links.twitter && (
      <OutboundLink
        href={`${links.twitter}`}
        title="Twitter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </OutboundLink>
    )}
    {links.medium && (
      <OutboundLink
        href={`${links.medium}`}
        title="Medium"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faMedium} />
      </OutboundLink>
    )}
  </div>
)

IconList.propTypes = {
  email: PropTypes.string.isRequired,
  site: PropTypes.string,
  links: PropTypes.object.isRequired as PropTypes.Validator<IconListProps["links"]>,
}

export default IconList
