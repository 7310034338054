import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import IconList from "../components/IconList"
import "./members.scss"

// used for sort
const titleRank = new Map([
  ["Professor", 0],
  ["Research Associate", 1],
  ["PhD Student", 2],
  ["Master Student", 3],
  ["Research Assistant", 4],
  ["Graduated", 5],
  ["Undergraduate", 6],
])

const ProfileCard = ({ profile }) => (
  <div className="profile-card" key={profile.id}>
    <Img
      className="photo"
      alt={`The portrait of ${profile.firstName} ${profile.familyName}`}
      fixed={profile.portrait}
      imgStyle={{ objectFit: "contain" }}
    />
    <div className="major-text">{profile.firstName + " " + profile.familyName}</div>
    <div className="minor-text">
      {profile.title}
      {profile.year ? ` (${profile.year})` : null}
    </div>
    <IconList email={profile.email} site={profile.site} links={profile.links} />
  </div>
)

ProfileCard.propTypes = {
  profile: PropTypes.object.isRequired,
}

const ProfileCardGallery = ({ title, profiles }) => (
  <>
    <h2>{title}</h2>
    <div className="members__profile-card-gallery">
      {profiles.map((m) => (
        <ProfileCard key={m.id} profile={m} />
      ))}
    </div>
  </>
)

ProfileCardGallery.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const MembersPage = () => {
  const queryResult = useStaticQuery(graphql`
    query MemberPageQuery {
      members: allMarkdownRemark(
        sort: { fields: frontmatter___startDate, order: DESC }
        filter: {
          frontmatter: { hidden: { ne: true } }
          fields: { sourceInstanceName: { eq: "members" } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              firstName
              familyName
              email
              title
              year
              photoUrl
              site
              faculty
              alumni
              links {
                instagram
                twitter
                medium
                github
              }
            }
          }
        }
      }
      allFile(filter: { sourceInstanceName: { eq: "portraits" } }) {
        nodes {
          childImageSharp {
            fixed(
              width: 180
              height: 180
              background: "rgba(0,0,0,0)"
              toFormat: PNG
              fit: CONTAIN
            ) {
              ...GatsbyImageSharpFixed
              originalName
            }
          }
        }
      }
    }
  `)
  const members = queryResult.members.edges.map((x) => ({ ...x.node.frontmatter, id: x.node.id }))
  const portraits = queryResult.allFile.nodes
  for (const member of members) {
    const portrait = portraits.find((x) => x.childImageSharp.fixed.originalName === member.photoUrl)
    member.portrait = portrait === undefined ? null : portrait.childImageSharp.fixed
  }
  const teachers = members.filter((x) => x.faculty)
  const doctors = members.filter((x) => !x.faculty && !x.alumni && x.title === "PhD Student")
  const masters = members.filter((x) => !x.faculty && !x.alumni && x.title === "Master Student")
  const alumni = members.filter((x) => x.alumni)
  const comparator = (x, y) => {
    const rankX = titleRank.get(x.title)
    const rankY = titleRank.get(y.title)
    const yearX = x.year || new Date().getFullYear()
    const yearY = y.year || new Date().getFullYear()
    return rankX === rankY
      ? yearX === yearY
        ? x.firstName.localeCompare(y.firstName)
        : yearX - yearY
      : rankX - rankY
  }
  teachers.sort(comparator)
  doctors.sort(comparator)
  masters.sort(comparator)
  alumni.sort(comparator)
  return (
    <Layout>
      <SEO title="Members" />
      <ProfileCardGallery title="Faculty" profiles={teachers} />
      <ProfileCardGallery title="PhD Students" profiles={doctors} />
      <ProfileCardGallery title="Master Students" profiles={masters} />
      <ProfileCardGallery title="Alumni" profiles={alumni} />
    </Layout>
  )
}

export default MembersPage
